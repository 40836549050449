@layer utilities {
  .cut-out-corner-card::after {
    @apply absolute w-0 -bottom-1 -left-1 z-10;
    content: '';
    border-bottom: var(--size) solid var(--color-bl);
    border-right: var(--size) solid transparent;
  }

  .cut-out-corner-card::before {
    @apply absolute w-0 -top-1 -right-1 z-10;
    content: '';
    border-top: var(--size) solid var(--color-tr);
    border-left: var(--size) solid transparent;
  }
}
